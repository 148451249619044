<template>
  <input
    v-model="value"
    v-bind="inputAttrs"
    :type="computedInputType"
    @focus="isFocused = true"
    @blur="isFocused = false" />
</template>

<script>
import DateInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/dateInput.js";

export default {
  mixins: [DateInputMixin],
};
</script>

<style scoped lang="scss"></style>
